export const syncGoodReads = (GRBooks) => {
  GRBooks.map((b, index) => {
    const { book } = b;
    const {
      authors,
      image_url,
      num_pages,
      publication_year,
      publication_day,
      publication_month,
      title_without_series,
      title,
      average_rating,
      ratings_count,
    } = book;

    const variables = {
      author: authors.author.name,
      title: title_without_series,
      series: title,
      pages: Number(num_pages),
      publishedDate: `${publication_day}/${publication_month}/${publication_year}`,
      releaseYear: Number(publication_year),
      image: image_url,
      orderNumber: index + 1,
      averageRating: Number(average_rating),
      ratingsCount: Number(ratings_count),
    };

    const ADD_BOOK = `mutation AddBook($author: String!, $title: String!, $series: String!, $pages: Float!, $publishedDate: String!, $releaseYear: Float!, $image: String!, $orderNumber: Float!, $ratingsCount: Float, $averageRating: Float ){
      createBook(data:{ author: $author,
      title: $title,
      series: $series,
      pages: $pages,
      publishedDate: $publishedDate,
      releaseYear: $releaseYear,
      image: $image
      orderNumber: $orderNumber
      averageRating: $averageRating,
      ratingsCount: $ratingsCount
    }){
        title
        author
        pages
        orderNumber
      }
    }`;

    const createBook = async () =>
      await fetch("http://localhost:3000/admin/api", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: ADD_BOOK,
          variables,
        }),
      })
        .then((resp) => resp.json())
        .then((data) => console.log("DATA FROM MUTATION : ", data));

    createBook();
    return b;
  });
};
