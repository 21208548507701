import React, { createContext, useState, useEffect } from "react";

import moment from "moment";

import { deleteBookMutation } from "../Mutations/DeleteBook";
import { updateBookOrder } from "../Mutations/UpdateBookOrder";
import { markBookAsReadMutation } from "../Mutations/MarkBookAsRead";
import { setReadingGoal } from "../Mutations/SetReadingGoal";
import { updateUserDataMutation } from "../Mutations/UpdateUserDataMutation";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const currentYear = moment().year();

console.log("AT THE VERY VERY STATE TOP");
console.log("currentYear", currentYear);

const LocalStateContext = createContext();
const LocalStateProvider = LocalStateContext.Provider;

const NoveListStateProvider = ({ children }) => {
  // STATE HOOKS
  const [user, setUser] = useState(false);
  const [booksRead, setBooksRead] = useState(0);
  const [finishedBooks, setFinishedBooks] = useState({});
  const [bookGoal, setBookGoal] = useState(false);
  const [bookGoals, setBookGoals] = useState(false);
  const [bookList, setBookList] = useState([]);
  const [shadowList, setShadowList] = useState([]);
  const [dragable, setDragable] = useState(true);
  const [sorting, setSorting] = useState("ORDER_ASC");
  const [loading, setLoading] = useState(true);

  const addReadingGoal = async ({ goal, userId }) => {
    const newGoal = await setReadingGoal({
      goal,
      userId,
    });

    setBookGoal(newGoal);
  };

  const sortUserBooks = async (sortBy) => {
    console.log("Going to sortUserBooks");
    console.log("sortBy", sortBy);
    setSorting(sortBy);
  };

  //CHECK FOR USER: Looks for user item in local storage
  const userCheck = () => {
    if (localStorage.getItem("user")) {
      setUser(JSON.parse(localStorage.getItem("user")));
    }
  };

  // CHECKS for user once on first initialization
  useEffect(() => {
    userCheck();
  }, []);

  //LOGOUT USER
  const logout = () => {
    localStorage.setItem("user", "");
  };
  console.log("user :>> ", user);

  //UPDATE THE SORTING: Works Like a Charm

  useEffect(() => {
    let booksSorted;
    console.log("CALLING OUR SORTING EFFECT");
    switch (sorting) {
      case "ORDER_ASC":
        console.log("SORTING BY ORDER_ASC");
        setDragable(true);
        break;
      case "averageRating_DESC":
        console.log("SORTING BY averageRating_DESC");
        booksSorted = [...bookList].sort(
          (a, b) => b.book.averageRating - a.book.averageRating
        );
        console.log("booksSorted", booksSorted);
        setDragable(false);
        setShadowList(booksSorted);
        break;
      case "averageRating_ASC":
        console.log("SORTING BY averageRating_ASC");
        booksSorted = [...bookList].sort(
          (a, b) => a.book.averageRating - b.book.averageRating
        );
        console.log("booksSorted", booksSorted);
        setDragable(false);
        setShadowList(booksSorted);
        break;
      case "title_DESC":
        console.log("SORTING BY title_DESC");
        booksSorted = [...bookList].sort((a, b) =>
          b.book.title > a.book.title ? 1 : -1
        );
        setDragable(false);
        setShadowList(booksSorted);
        break;
      case "title_ASC":
        console.log("SORTING BY title_ASC");
        booksSorted = [...bookList].sort((a, b) =>
          a.book.title > b.book.title ? 1 : -1
        );
        setDragable(false);
        setShadowList(booksSorted);
        break;
      case "pages_ASC":
        console.log("pages_ASC");
        booksSorted = [...bookList].sort((a, b) => a.book.pages - b.book.pages);
        setDragable(false);
        setShadowList(booksSorted);
        break;
      case "pages_DESC":
        console.log("SORTING BY pages_DESC");
        booksSorted = [...bookList].sort((a, b) => b.book.pages - a.book.pages);
        setDragable(false);
        setShadowList(booksSorted);
        break;

      default:
        booksSorted = [...bookList].sort((a, b) => a.order - b.book.order);
        console.log("Default Sorting....");
        setShadowList(booksSorted);
        break;
    }
  }, [sorting]);

  const deleteBook = ({ id, user }) => {
    deleteBookMutation({ id, user });
    setBookList([...bookList].filter((book) => book._id !== id));
  };

  const markBookAsComplete = ({ id, user }) => {
    console.log(
      "ABOOUT TO SEND MARKASCOMPLETE MUTATION TO SERVER WITH THE FOLLOWING INFO"
    );
    console.log({ user, id });
    markBookAsReadMutation({
      userId: user,
      books: bookList,
      bookId: id,
      currentYear: moment().year(),
    });
    // deleteBookMutation({ id, user });
  };

  const deleteBookFromUser = ({ bookId, userId }) => {
    console.log("ATTEMPTING TO REMOVEBOOKFROMUSER USING MUTATION......");

    console.log("bookId", bookId);
    deleteBookMutation({ id: bookId, user: userId });
    setBookList([...bookList].filter((book) => book.book._id !== bookId));
  };

  const sendBookToTop = async (id) => {
    let reorderedBooks = [...bookList];
    reorderedBooks.forEach(function (item, i) {
      if (item.book._id === id) {
        reorderedBooks.splice(i, 1);
        reorderedBooks.unshift(item);
      }
    });

    updateBookList(reorderedBooks);
  };

  const sendBookToBottom = async (id) => {
    let reorderedBooks = [...bookList];
    reorderedBooks.forEach(function (item, i) {
      if (item.book._id === id) {
        reorderedBooks.splice(i, 1);
        reorderedBooks.push(item);
      }
    });

    updateBookList(reorderedBooks);
  };

  //Calculate Total Pages to Read for Number of Books in BookGoal
  let sliceBooks;
  console.log("bookList", bookList);
  if (bookGoal > booksRead) {
    sliceBooks = bookList.slice(0, bookGoal - booksRead);
  } else {
    sliceBooks = bookList.slice(0, bookGoal);
  }

  console.log("sliceBooks", sliceBooks);

  let totalPages = sliceBooks.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.book.pages;
  }, 0);

  // useEffect(() => {
  //   console.log("OUR INITIAL DATA HAS ARRIVED");
  //   console.log("allBooksData :>> ", allBooksData);
  //   if (allBooksData && allBooksData.books) {
  //     setBookList(allBooksData.books);
  //   }
  // }, [allBooksData, setBookList]);

  //GET USER'S READING DETAILS, NEEDS LOGGED IN USER, WILL RETURN bookList, goals, and finished books, then sets them via state hooks
  useEffect(() => {
    console.log("OUR INITIAL DATA HAS ARRIVED");
    const fetchData = async () => {
      await fetch(`${BACKEND_URL}/readingDetails`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          payload: {
            user,
          },
        }),
      })
        .then((resp) => resp.json())
        .then((data) => {
          console.log("data_from_Booklist_Fetch :>> ", data);
          let currentYear = moment().year();
          if (data.payload) {
            console.log("IF DATA TESTSSS");
            setBookList(data.payload.books.sort((a, b) => a.order - b.order));
            setBookGoals(data.payload.readingGoals);
            setLoading(false);
            data.payload.books.sort((a, b) => a.book.order - b.book.order);
            if (data.payload.readingGoals) {
              let currentGoal = data.payload.readingGoals.filter(
                (goals) => goals.year === currentYear
              )[0]?.goal;
              if (currentGoal) {
                setBookGoal(currentGoal);
              }
            }
            setFinishedBooks(data.payload.finishedBooks);
            if (
              data.payload.finishedBooks &&
              Object.keys(data.payload.finishedBooks).length > 0
            ) {
              let currentRead = data.payload.finishedBooks[currentYear]
                ? data.payload.finishedBooks[currentYear].length
                : 0;

              if (currentRead) {
                setBooksRead(currentRead);
              }
            } else {
              setBooksRead(0);
            }
          }
        });
    };
    fetchData();
  }, [user]);

  const updateUserData = async () => {
    const data = await updateUserDataMutation(user);

    console.log("DAAAAAAAAAAAAAAAAAAAAAAATA");
    console.log("data", data);

    let currentYear = moment().year();
    if (data.payload) {
      if (data.payload.books) {
        setBookList(data.payload.books.sort((a, b) => a.order - b.order));
      }

      if (data.payload.readingGoals) {
        setBookGoals(data.payload.readingGoals);
        // data.payload.books.sort((a, b) => a.book.order - b.book.order);
      }

      if (data.payload.readingGoals) {
        let currentGoal = data.payload.readingGoals.filter(
          (goals) => goals.year === currentYear
        )[0]?.goal;
        if (currentGoal) {
          setBookGoal(currentGoal);
        }
      }

      if (data.payload.finishedBooks) {
        setFinishedBooks(data.payload.finishedBooks);
        let currentRead = data.payload.finishedBooks[currentYear]
          ? data.payload.finishedBooks[currentYear].length
          : 0;
        if (currentRead) {
          setBooksRead(currentRead);
        }
      }
    }
  };

  const updateBookList = (books) => {
    setBookList(books);
    updateBookOrder({ user, books });
  };

  return (
    <LocalStateProvider
      value={{
        addReadingGoal,
        bookList,
        loading,
        setBookList,
        bookGoals,
        shadowList,
        finishedBooks,
        setShadowList,
        updateBookList,
        markBookAsComplete,
        sorting,
        setSorting,
        setReadingGoal,
        sendBookToBottom,
        sendBookToTop,
        sortUserBooks,
        updateBookOrder,
        updateUserData,
        dragable,
        setDragable,
        logout,
        user,
        setUser,
        bookGoal,
        setBookGoal,
        booksRead,
        totalPages,
        deleteBook,
        deleteBookFromUser,
      }}
    >
      {children}
    </LocalStateProvider>
  );
};

export { NoveListStateProvider, LocalStateContext };
