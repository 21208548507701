const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const scrapeBookGoodReads = async (url, length) => {
  console.log(
    "Attempting to use our scrapeBookGoodReads Mutation.... Firing Off Request"
  );

  const resData = await fetch(`${BACKEND_URL}/scrapeinformation`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "https://thenovelist.ca",
    },
    body: JSON.stringify({
      payload: {
        url,
        length,
      },
    }),
  }).then((resp) => resp.json());

  return resData;
};
