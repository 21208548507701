import React, { useState, useContext } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import IllustrationReading from "../Illustrations/Reading";
import { LocalStateContext } from "../State/LocalState";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const Title = styled.div`
  box-sizing: border-box;
  margin-top: 0;
  font-weight: 500;
  line-height: 1.2;
  font-size: 2.5rem;
  margin-bottom: 1rem !important;
`;

const Label = styled.div`
  display: inline-block;
  margin-bottom: 0.5rem;
`;

const FormControl = styled.input`
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

const FormGroup = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  box-sizing: border-box;
  margin-bottom: 1rem;
`;

const FormButton = styled.div`
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-transform: none;
  text-transform: none;
`;

const MainContainer = styled.div`
  box-sizing: border-box;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 720px;
  width: 50% !important;
  margin-top: 3rem !important;
`;

const LoginContainer = styled.div`
  margin-top: 100px;
  display: grid;
  grid-template-columns: 3fr 4fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: ". .";
`;

const LoginGraphic = styled.div`
  display: flex;

  flex-direction: column;

  flex-wrap: nowrap;

  justify-content: flex-start;

  align-content: center;

  align-items: center;
`;

export const Login = () => {
  let location = useLocation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [redirect, setRedirect] = useState(false);

  const { user, setUser } = useContext(LocalStateContext);

  const login = async ({ email, password }) => {
    let authenticatedUser;
    await fetch(`${BACKEND_URL}/auth`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        payload: {
          email,
          password,
        },
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log("data 2:>> ", data);
        authenticatedUser = data.user ? data.user : false;
        if (authenticatedUser) {
          console.log("authenticatedUser :>> ", authenticatedUser);

          localStorage.setItem("user", JSON.stringify(authenticatedUser));

          setUser(authenticatedUser);
          setRedirect(true);
        } else {
          alert("FAIL");
        }
      });
  };

  return (
    <MainContainer>
      <Routes>
        <Route
          path="/auth"
          element={
            user && <Navigate to="/main" state={{ from: location }} replace />
          }
        />
      </Routes>

      <LoginContainer>
        {redirect && <Navigate to="/main" state={{ from: location }} replace />}
        <LoginGraphic>
          <Title className="mb-3">Login</Title>
          <IllustrationReading width="334" height="250" />
        </LoginGraphic>
        <div>
          <FormGroup>
            <Label>Email address</Label>
            <FormControl
              type="email"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  login({ email, password });
                }
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleInputPassword1">Password</Label>
            <FormControl
              type="password"
              id="exampleInputPassword1"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  login({ email, password });
                }
              }}
            />
          </FormGroup>

          <FormButton onClick={() => login({ email, password })}>
            Login
          </FormButton>
        </div>
      </LoginContainer>
    </MainContainer>
  );
};
