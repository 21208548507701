const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const addBook = async ({ bookData, user, length }) => {
  console.log("CALLING ADD BOOK");
  console.log("bookData", bookData);
  console.log("bookData", user);
  const fetchData = await fetch(`${BACKEND_URL}/addbooktolist`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      payload: {
        bookData,
        user,
        length,
      },
    }),
  })
    .then((resp) => resp.json())
    .then((data) => {
      console.log("DATA FROM MUTATION : ", data);
      return data;
    });
  return fetchData;
};
